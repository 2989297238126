<template>
  <div class="bp-newsletter-modal noselect flex--col-center-center">
    <button
      class="bp-newsletter-modal__close-cross"
      @click="() => (isSubscribed ? closeModal() : dismissModal())"
    >
      <Cross />
    </button>
    <div
      class="bp-newsletter-modal__content-container flex--col-center-center text--clr-black"
      :class="{
        'bp-newsletter-modal__content-container--hidden': isSubscribed
      }"
    >
      <p class="bp-newsletter-modal__title text-commerce-heading-2">
        {{ UI_ELEMENT("sign_up_for_discount_title") }}
      </p>
      <p class="bp-newsletter-modal__sub-title text-body">
        {{ UI_ELEMENT("sign_up_for_discount_subtitle") }}
      </p>
      <form class="bp-newsletter-modal__input-button-container">
        <FormInput
          class="bp-newsletter-modal__form-input"
          type="email"
          inputId="email"
          float
          :center="!GET_IS_ABOVE_BREAKPOINT('lg')"
          noLabel
          v-model="userEmail"
          placeholder="input_placeholder_email"
          :disabled="subscribingLoading || isSubscribed"
          @submit="subscribeUser"
        />
        <Button
          class="bp-newsletter-modal__form-button"
          :disabled="!isEmailValid || subscribingLoading || isSubscribed"
          @click="subscribeUser"
          >{{ UI_ELEMENT("newsletter_CTA") }}</Button
        >
      </form>
      <p
        class="bp-newsletter-modal__error text--clr-black text-body text--w-bold"
        :class="{ 'bp-newsletter-modal__error--visible': subscribingError }"
      >
        {{ subscribingError ? UI_ELEMENT(subscribingError) : "" }}
      </p>
      <button
        class="bp-newsletter-modal__close-modal-text"
        @click="dismissModal"
      >
        <p
          class="bp-newsletter-modal__close-modal-text text-zd-smallprint"
          v-if="GET_IS_ABOVE_BREAKPOINT('lg')"
        >
          {{ UI_ELEMENT("close_modal_text") }}
        </p>
      </button>
    </div>
    <div
      class="bp-newsletter-modal__content-container bp-newsletter-modal__content-container--results flex--col-center-center text--clr-black"
      :class="{
        'bp-newsletter-modal__content-container--hidden': !isSubscribed
      }"
    >
      <p class="bp-newsletter-modal__title text-commerce-heading-2">
        {{ UI_ELEMENT("signed_up_for_discount_title") }}
      </p>
      <p class="bp-newsletter-modal__sub-title text-body">
        {{ UI_ELEMENT("signed_up_for_discount_subtitle") }}
      </p>
      <Button @click="closeModal">{{
        UI_ELEMENT("newsletter_finish_CTA")
      }}</Button>
    </div>
    <div class="bp-newsletter-modal__success-bubbles" v-show="isSubscribed">
      <div
        class="success-bubble"
        :id="`bubble_${index}`"
        v-for="(bubble, index) in bubbles"
        :key="index"
      >
        <SvgSprite
          :svgSrc="Face"
          :id="`bubble_svg_${index}`"
          :sprite="
            ['Base', 'Grin', 'Pop1', 'Pop2', 'Empty'][Math.floor(bubble.frame)]
          "
          class="success-bubble__face"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ModalComponentMixin from "@/plugins/ModalContainer/ModalComponentMixin"

import { gsap } from "gsap"
import { Cubic, MotionPathPlugin } from "gsap/all"
gsap.registerPlugin(Cubic, MotionPathPlugin)

import { mapGetters } from "vuex"
import { validateEmail } from "@/utils"
import BleachAPI from "@/integration/BleachAPI"
import FormInput from "@/components/form/FormInput.vue"
import Button from "@/components/buttons/BasicButton.vue"

import Cross from "@/assets/cross.svg"
import Face from "./../../assets/sprites/face_test.svg?external"

export default {
  name: "NewsletterModal",

  // specific config for the enter/leave animations for this modal
  transitions: {
    xs: ["slide-up", 300],
    lg: ["center-slide-up", 300]
  },

  mixins: [ModalComponentMixin],

  data: () => ({
    isSubscribed: false,
    userEmail: "",
    subscribingError: null,
    subscribingLoading: false,
    Face,
    bubbles: []
  }),

  components: {
    Cross,
    FormInput,
    Button
  },

  mounted() {
    this.attachBubbles()
  },

  methods: {
    subscribeUser() {
      this.subscribingError = null
      if (this.isEmailValid && !this.subscribingLoading) {
        this.subscribingLoading = true
        BleachAPI.subscribeToNewsletter(this.userEmail)
          .then(res => {
            this.subscribingLoading = false
            if (res.data.newsletterSignup.result) {
              this.isSubscribed = true
              this.animateBubbles()
            } else {
              this.subscribingError = "newsletter_error_backend"
            }
          })
          .catch(() => {
            this.subscribingError = "newsletter_error_backend"
            this.subscribingLoading = false
          })
      } else {
        this.subscribingError = "newsletter_error_invalid"
      }
    },
    dismissModal() {
      this.reject()
      this.closeModal()
    },

    closeModal() {
      // All modals need to call this.emitClose() from their own close method
      this.emitClose()
    },

    attachBubbles() {
      for (let i = 0; i < 3; i++) {
        this.bubbles.push({
          el: `bubble_${i}`,
          svg: `bubble_svg_${i}`,
          frame: 1
        })
      }
    },

    animateBubbles() {
      let randomPath = () => {
        let randomBetween = (min, max) => Math.random() * (max - min) + min
        let pathArr = []
        // Array of 4 coordinates to create a cubic bezier path
        for (let i = 0; i < 4; i++) {
          pathArr.push({
            x: randomBetween(-30, 30),
            y: i * randomBetween(-40, -50)
          })
        }
        return pathArr
      }

      this.bubbles.forEach((bubble, i) => {
        // Animate the sprite frames
        gsap.to(bubble, {
          duration: "random(2,3)",
          delay: i * 0.1,
          frame: 4,
          ease: Cubic.easeIn
        })
        // Float up
        gsap.to(document.getElementById(bubble.el), {
          duration: "random(2, 4)",
          scale: 1.1,
          transformOrigin: "center",
          motionPath: {
            path: randomPath(),
            type: "cubic",
            curviness: 2,
            autoRotate: false
          }
        })
      })
    }
  },
  computed: {
    ...mapGetters("breakpoints", ["GET_IS_ABOVE_BREAKPOINT"]),
    isEmailValid() {
      return validateEmail(this.userEmail)
    }
  },
  watch: {
    userEmail(newVal, oldVal) {
      if (newVal != oldVal) {
        this.subscribingError = null
      }
    }
  }
}
</script>

<style lang="scss">
$padding-top-default: 60px;
$padding-top-lg: 70px;

.bp-newsletter-modal {
  position: relative;
  background-color: getcolour(bleach_purple);
  padding: $padding-top-default 30px 30px;
  pointer-events: all;

  @include media-breakpoint-up(lg) {
    padding: $padding-top-lg 30px 50px;
  }

  &__content-container {
    z-index: 1;
    width: 100%;
    text-align: center;

    transition: opacity 0.1s linear 0.1s;

    &--results {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 40px 30px 30px;
    }

    &--hidden {
      opacity: 0;
      transition: opacity 0.2s linear;
      pointer-events: none;
    }
  }

  &__close-cross {
    position: absolute;
    z-index: 2;
    background-color: transparent;
    top: 20px;
    right: 30px;
    width: 26px;
    height: 26px;
    padding: 3px;
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 0;

    path {
      stroke: getcolour(bleach_black);
      vector-effect: non-scaling-stroke;
      stroke-width: 1.5px;
    }

    body:not(.mouse-nav) &:focus {
      outline: 2px solid getcolour(bleach_white);
    }

    path {
      stroke: getcolour(bleach_black);
    }
  }

  &__title {
    margin-bottom: 0.5em;
  }

  &__sub-title {
    margin-bottom: 2em;
  }

  &__input-button-container {
    border: 1px solid $bleach_black;
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      border-width: 2px;
      max-width: 456px;
    }
  }
  &__form-input {
    min-height: 40px;
    text-align: center;

    @include media-breakpoint-up(lg) {
      min-width: 200px;
      max-width: 314px;
    }
  }
  &__form-button {
    flex-grow: 1;
    padding: 18px;
    margin: 0;
    width: 100%;
    min-height: 0;
    height: 40px;
    @include media-breakpoint-up(lg) {
      padding: 12px;
      width: initial;
    }
  }
  &__error {
    opacity: 0;
    transition: opacity 0.1s linear;
    height: 2em;
    margin-top: 10px;

    &--visible {
      opacity: 1;
    }
  }
  &__close-modal-text {
    text-decoration: underline;
    background-color: transparent;
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 0;

    body:not(.mouse-nav) &:focus {
      color: getcolour(bleach_white);
    }
  }

  &__success-bubbles {
    position: absolute;
    top: $padding-top-default;
    left: 0;
    width: 100%;
    height: 30vh;
    transform: translateY(-100%);

    @include media-breakpoint-up(lg) {
      top: $padding-top-lg;
    }
  }
}
.success-bubble {
  position: absolute;
  width: 22px;
  height: 22px;
  transform: translateX(-50%) rotate(180deg);
  top: 100%;

  @for $i from 1 through 3 {
    &:nth-child(#{$i}) {
      position: absolute;
      left: calc((40% * #{$i}) - 30%);
    }
  }
}
</style>
