<template>
  <div class="bp-modal-store-locale noselect">
    <div class="bp-modal-store-locale__text-wrapper">
      <div
        class="bp-modal-store-locale__title text-commerce-heading-2 text--clr-black text--uppercase"
      >
        {{
          UI_ELEMENT(
            `store_locale_switch_title_${this.contextData}_${this
              .targetLocale || this.suggestedLocale}`
          )
        }}
      </div>
      <div class="bp-modal-store-locale__copy text-body text--clr-black">
        {{
          UI_ELEMENT(
            `store_locale_switch_body_${this.contextData}_${this.targetLocale ||
              this.suggestedLocale}`
          )
        }}
      </div>
      <BasicButton
        class="bp-modal-store-locale__button"
        large
        @click="handleConfirm"
        >{{
          UI_ELEMENT(
            `store_locale_switch_button_${this.contextData}_${this
              .targetLocale || this.suggestedLocale}`
          )
        }}</BasicButton
      >
      <div
        class="bp-modal-store-locale__reject text-body text--underline text--clr-black"
        @click="handleReject"
      >
        {{
          UI_ELEMENT(
            `store_locale_switch_reject_${this.contextData}_${this.locale}`
          )
        }}
      </div>
    </div>
  </div>
</template>

<script>
import GTMAPI from "@/integration/GTMAPI"
import ModalComponentMixin from "@/plugins/ModalContainer/ModalComponentMixin"
import BasicButton from "@/components/buttons/BasicButton"
import { mapState, mapActions } from "vuex"

let contextOptions = {
  geo: "geo",
  pref: "pref",
  user: "user"
}

export default {
  name: "StoreLocaleModal",
  // specific config for the enter/leave animations for this modal
  transitions: {
    xs: ["slide-up", 300]
  },

  mixins: [ModalComponentMixin],
  props: ["context", "targetLocale", "targetShop"],
  data: () => ({
    contextData: null
  }),
  components: {
    BasicButton
  },

  created() {
    this.contextData = contextOptions[this.context]
  },
  methods: {
    ...mapActions("locale", ["CHANGE_LOCALE"]),

    handleConfirm() {
      GTMAPI.trackEvent(
        "change_store.confirm.clicked",
        this.trackingDataPayload
      )
      this.close()
      this.changeLocale()
    },

    handleReject() {
      GTMAPI.trackEvent("change_store.reject.clicked", this.trackingDataPayload)
      this.reject("keepLocale")
      this.close()
    },

    changeLocale() {
      this.CHANGE_LOCALE({
        locale: this.targetLocale || this.suggestedLocale,
        shopName: this.targetShop || this.suggestedShop
      })
    },

    close() {
      // All modals need to call this from their own close() method
      this.emitClose()
    }
  },
  computed: {
    ...mapState("locale", [
      "locale",
      "shopName",
      "suggestedLocale",
      "suggestedShop",
      "queueGeoFetch"
    ]),

    trackingDataPayload() {
      return {
        current_locale: this.locale,
        target_locale: this.targetLocale || this.suggestedLocale,
        context: this.contextData
      }
    }
  }
}
</script>
<style lang="scss">
.bp-modal-store-locale {
  // width: 100%;
  background-color: getcolour(bleach_peach);
  text-align: center;
  padding: 20px 30px;
  pointer-events: all;

  &__text-wrapper {
    position: relative;
    margin: 0 auto;
    max-width: 700px;
  }

  &__title {
    margin: 20px auto;

    @include media-breakpoint-up(md) {
      margin: 20px auto;
    }
  }

  &__copy {
    margin: 10px auto;

    @include media-breakpoint-up(md) {
      margin: 20px auto;
    }
  }

  &__button {
    margin: 30px auto;

    @include media-breakpoint-up(md) {
      margin: 40px auto;
    }
  }

  &__reject {
    cursor: pointer;
    margin: 10px auto;

    @include media-breakpoint-up(md) {
      margin: 20px auto;
    }
  }
}
</style>
